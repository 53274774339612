import React, { Component } from "react";
import AbsaleCard from "./../../OrderProcess/AbsaleCard";
import axios from "axios";
import { api_get_experience_list, api_get_activity_option_list, api_get_voucher_monetary_value } from "../../../api/api";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  InputGroupAddon,
  Popover,
  PopoverBody
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import {
  FaPlus
} from "react-icons/fa";
// import "react-phone-input-2/dist/style.css";
let hasActive = false;
let fields = [];
let is_continue = false;
const SCREEN_WIDTH_LIMIT = 1000;

var campaign_value = 0;

class VoucherDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      first_name: "",
      btnPay: false,
      amount: null,
      product_list: [],
      ticket_options: [],
      calculate_product_list: [],
      draft_calculate: {
        product_id: null,
        segment_id: null,
        qty: 0,
        product: null,
        segment: null
      },
      validate: { emailState: "", fnState: "", nameState: "", phoneState: "" },
      campaign: [],
      campaign_message: "",
      height: window.innerHeight,
      width: window.innerWidth,
      openpopoverTicketOptions: false,
      classFocus: "ro",
      ticketCollapse: true,
      ticketOption: "",
      ticketOptionID: null,
      showVoucherDetail: false,
      colors: {
        color1: null,
        color2: null,
        color3: null,
        color4: null,
      },
      load_ticket: false,
      monetary_value: [],
      editable_voucher_amount: null
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  


  get_campaign_message = (value) => {
    let message = ""
    const rules = this.props.campaign.rules
    let next_rule = 0;
    const valid_msg = "Voucher valid for bookings created after <br/>" + this.props.campaign.valid_from
    if (rules) {
      for (let index = 0; index < rules.length; index++) {
        if (value >= rules[index].min_value && rules[index].max_value >= value) {
          next_rule = index + 1;
        }
      }
      if (next_rule < rules.length) {
        message = "<div class='font-weight-bold h6'>TOP UP Voucher Special <br/> Buy $" + rules[next_rule].min_value + ", receive $" + rules[next_rule].bonus_value + " extra value! </div>" + valid_msg
      }
      else if (next_rule == rules.length) {
        message = "<div class='font-weight-bold h6'>TOP UP Voucher Special <br/> You're eligible for a <br/> +$" + rules[next_rule - 1].bonus_value + " TOP UP on your voucher! </div>" + valid_msg
      }
    }
    return message
  }

  get_monetary_value = () => {
    const params = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        park_id: this.props.park_id
      }
    };
    axios
      .post(api_get_voucher_monetary_value, params, {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      })
      .then(({ data }) => {
        if ("result" in data) {
          this.setState({monetary_value: data.result})
          if (data.result.length > 0) {
            this.setState({showVoucherDetail: false})
          }
        }
      })
      .catch(({ err }) => {});
  }
  
  render() {
    const { cardStyle, numberCircle, cardClass } = this.props;
    const headCard = this.getheadCard();
    const cardCollapse = this.getCardDetail();
    return (
      <AbsaleCard
        cardStyle={cardStyle}
        cardClass={cardClass}
        numberCircle={numberCircle}
        headCard={headCard}
        step="2"
        cardCollapse={cardCollapse}
        collapse={this.props.VoucherDetailCollapse}
        data={this.props.data}
        actionCollapse={this.props.actionCollapse}
        name="VoucherDetailCollapse"
        parks={this.props.parks}
      />
    );
  }

  calculate_amount = () => {
    var res = 0;
    // eslint-disable-next-line
    this.state.calculate_product_list
      .filter(cp => cp.segment != null)
      // eslint-disable-next-line
      .map(p => {
        res = res + p.segment.price * p.qty
      });
    campaign_value = res
    if (this.state.draft_calculate.segment) {
      res = res + this.state.draft_calculate.segment.price * this.state.draft_calculate.qty
    }
    this.setState({ amount: res });
    return res
  };

  addCalculateProduct = () => {
    console.log('a');
    let state = { ...this.state };
    state.calculate_product_list.push(state.draft_calculate);
    state.draft_calculate = {
      product_id: null,
      segment_id: null,
      qty: 0,
      product: null,
      segment: null
    };
    this.setState(state);
    this.calculate_amount();
  };
  removeCalculateProduct = (idx) => {
    let state = { ...this.state };
    console.log(state.calculate_product_list);
    state.calculate_product_list.splice(idx-1,1);
    console.log(idx);
    console.log(state.calculate_product_list);
    this.setState(state);
    this.calculate_amount();
  };
  get_experience = () => {
    const params = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        park_id: this.props.park_id
      }
    };
    axios
      .post(api_get_experience_list, params, {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      })
      .then(({ data }) => {
        this.setState({ product_list: data.result });
      })
      .catch(({ err }) => { });
  };

  get_ticket_options = () => {
    const params = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        park_id: this.props.park_id
      }
    };
    axios
      .post(api_get_activity_option_list, params, {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      })
      .then(({ data }) => {
        this.setState({ 
          ticket_options: data.result,
        });
        if (data.result.length == 1) {
          this.setState({
            ticketOption: data.result[0].name,
            ticketOptionID: data.result[0].id,
            showVoucherDetail: true,
          })
        }
        else {
          this.setState({
            showVoucherDetail: !this.state.showVoucherDetail 
          })
          setTimeout(() => this.openTicketOptions(), 1500)
        }
      })
      .catch(({ err }) => { });
  };
  
  updateDimensions() {
    this.setState({
      height: window.innerHeight, 
      width: window.innerWidth
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    let inputTooltipField = document.getElementById("amount")
    document.addEventListener('input', () => {
      ReactTooltip.show(inputTooltipField);
    })
    setTimeout(() => {
      this.get_experience();
    }, 100);

  }
  componentDidUpdate(prevProp, prevState) {
    if (prevProp.parks !== this.props.parks) {
      this.state.editable_voucher_amount = this.props.parks.editable_front_end_voucher_amount;
    }
    if (prevProp.enableTicketOptions !== this.props.enableTicketOptions) {
      setTimeout(() => {
        this.get_monetary_value();
      }, 500);
      const park = this.props.parks
      
      this.state.colors.color1 = park.t_color1;
      this.state.colors.color2 = park.t_color2;
      this.state.colors.color3 = park.t_color3;
      this.state.colors.color4 = park.t_color4;
    }
    if (prevProp.park_id !== this.props.park_id) {
      this.get_monetary_value();
    }
  }

  fieldCheck(fields) {
    if (
      fields["first_name"] != null &&
      fields["name"] != null &&
      fields["email"] != null &&
      fields["phone"] != null
    ) {
      if (
        fields["first_name"] > 0 &&
        fields["name"] > 0 &&
        fields["email"] > 0 &&
        fields["phone"] > 6
      ) {
        this.setState({ btnPay: true });
      } else {
        this.setState({ btnPay: false });
      }
    }
  }

  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state;
    if (emailRex.test(e.target.value)) {
      validate.emailState = "has-success";
    } else {
      validate.emailState = "has-danger";
    }
    this.setState({ validate });
  }

  validBlur(e) {
    const { validate } = this.state;
    validate.fnState = "has-success";
    this.setState({ validate });
  }

  validBlurName(e) {
    const { validate } = this.state;
    validate.nameState = "has-success";
    this.setState({ validate });
  }

  validBlurPhone(e) {
    const { validate } = this.state;
    if (e.target.value !== '') {
      const phoneRegex = /^[().+\d -]{1,15}$/;
      if (phoneRegex.test(e.target.value) && e.target.value.length > 6) {
        validate.phoneState = "has-success";
      } else {
        validate.phoneState = "has-danger";
      }
    } else {
      validate.phoneState = "";
    }
    this.setState({ validate })
  }

  handleChangeAmount = (event, maskedvalue, floatvalue) => {
    // eslint-disable-next-line
    if (floatvalue == 0) {
      floatvalue = null;
    }
    this.setState({ amount: floatvalue });
  };

  handleChange(e) {
    fields[e.target.name] = e.target.value.length;
    this.setState({ [e.target.name]: e.target.value });
    this.fieldCheck(fields);
  }

  handleChangeNumber(e) {
    let value = e.target.value.match(/^[().+\d -]{1,15}$/g, "");
    fields[e.target.name] = e.target.value.length;
    if (typeof value == "object" && value) {
      value = value[0].replace(/^0+/, "");
    }
    this.setState({ [e.target.name]: parseInt(value) });

    this.fieldCheck(fields);

    campaign_value = value;
  }
  handleSubmit(event) {
    alert("An essay was submitted: " + this.state.value);
  }

  getheadCard() {
    if (this.props.ProfileCardCollapse) {
      hasActive = true;
    }
    return (
      <React.Fragment>
        <div
          className={
            hasActive
              ? "font-weight-bold title"
              : "font-weight-bold title head-dis"
          }
        >
          Select Gift Amount
        </div>
      </React.Fragment>
    );
  }

  AddVoucherSubmit = (e, monetary_value) => {
    e.preventDefault();
    if (this.state.validate.phoneState !== "has-danger") {
      if (monetary_value) {
        this.props.addVoucher(
          {
            recipient_name: this.state.first_name,
            recipient_phone: this.state.phone,
            voucher_value: this.state.monetary_value.filter(item => item.selected == true)[0].value,
            recipient_email: this.state.email,
            voucher_type: 'fixed_monetary'
          },
          is_continue
        );
      }
      else {
        this.props.addVoucher(
          {
            recipient_name: this.state.first_name,
            recipient_phone: this.state.phone,
            voucher_value: this.state.amount,
            recipient_email: this.state.email,
            voucher_type: 'activity'
          },
          is_continue
        );
      }
      this.setState({
        first_name: "",
        phone: "",
        amount: "",
        email: "",
        calculate_product_list: [],
        draft_calculate: {
          product_id: null,
          segment_id: null,
          qty: 0,
          product: null,
          segment: null
        }
      });
    }
  };

  select2_list_product() {
    let res = [];
    // eslint-disable-next-line
    if (this.props.enableTicketOptions && this.state.ticketOptionID != null) {
      this.state.product_list.filter(obj => obj.activity_option_id === parseInt(this.state.ticketOptionID)).map(p => {
        res.push({ text: p.name, id: p.id });
      });
    }
    else {
      this.state.product_list.map(p => {
        res.push({ text: p.name, id: p.id });
      });
    }

    return res;
  }

  select2_list_segment(product) {
    let res = [];
    if (product) {
      // eslint-disable-next-line
      product.product.map(p => {
        res.push({ text: p.segment_id.name, id: p.segment_id.id });
      });
    }
    return res;
  }

  changeDraftProduct(e) {
    if (e.target) {
      const prod_index = e.target.name;
      let state = { ...this.state };
      // Check using double equals to check int value
      // eslint-disable-next-line
      var prod = state.product_list.filter(p => p.id == e.target.value);
      if (prod_index === "") {
        state.draft_calculate.product_id = e.target.value;
        state.draft_calculate.product =
          prod.length > 0 ? prod[0] : state.draft_calculate.product;
        state.draft_calculate.segment_id = null;
        state.draft_calculate.qty = 0;
      } else {
        state.calculate_product_list[parseInt(prod_index)].product_id =
          e.target.value;
        state.calculate_product_list[parseInt(prod_index)].product =
          prod.length > 0
            ? prod[0]
            : state.calculate_product_list[parseInt(prod_index)].product;
        state.calculate_product_list[parseInt(prod_index)].segment_id = null;
        state.calculate_product_list[parseInt(prod_index)].qty = 0;
      }
      this.setState(state);
      this.calculate_amount();
    }
  }

  changeDraftSegment(e) {
    if (e.target) {
      const prod_index = e.target.name;
      let state = { ...this.state };
      if (prod_index === "") {
        state.draft_calculate.segment_id = e.target.value;
        if (state.draft_calculate.product) {
          var segment = state.draft_calculate.product.product.filter(
            // eslint-disable-next-line
            p => p.segment_id.id == e.target.value
          );
          state.draft_calculate.segment =
            segment.length > 0 ? segment[0] : state.draft_calculate.segment;
        }
      } else {
        state.calculate_product_list[parseInt(prod_index)].segment_id =
          e.target.value;
        if (state.calculate_product_list[parseInt(prod_index)].product) {
          var segment = state.calculate_product_list[
            parseInt(prod_index)
            // eslint-disable-next-line
          ].product.product.filter(p => p.segment_id.id == e.target.value);
          state.calculate_product_list[parseInt(prod_index)].segment =
            segment.length > 0
              ? segment[0]
              : state.calculate_product_list[parseInt(prod_index)].segment;
        }
      }
      this.setState(state);
      this.calculate_amount();
    }
  }
  changeDraftqty(e) {
    if (e.target) {
      const prod_index = e.target.name;
      let state = { ...this.state };
      if (prod_index === "") {
        state.draft_calculate.qty = e.target.value;
      } else {
        state.calculate_product_list[parseInt(prod_index)].qty = e.target.value;
      }
      this.setState(state);
      const amount = this.calculate_amount();

      let inputTooltipField = document.getElementById("amount")
      campaign_value = amount;
      this.setState({ campaign_message: this.get_campaign_message(this.state.amount) });
      ReactTooltip.show(inputTooltipField)
    }
  }

  calculateProductListHtml(idx) {
    return (
      <FormGroup className="row" key={idx}>
        <div className="col-md-4" style={{ paddingRight: "0" }}>
          <Select2
            style={{ width: "100%" }}
            className="form-control"
            data={this.select2_list_product()}
            options={{
              placeholder: "Product"
            }}
            name={idx}
            onChange={this.changeDraftProduct.bind(this)}
            value={this.state.calculate_product_list[idx].product_id}
          />
        </div>
        <div className="col-md-4" style={{ paddingRight: "0" }}>
          <Select2
            name={idx}
            className="form-control"
            data={this.select2_list_segment(
              this.state.calculate_product_list[idx].product
            )}
            value={this.state.calculate_product_list[idx].segment_id}
            onChange={this.changeDraftSegment.bind(this)}
            options={{
              placeholder: "Segment"
            }}
          />
        </div>
        <div className="col-md-3" style={{ paddingRight: "0" }}>
          <Select2
            className="form-control"
            data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
            options={{
              placeholder: "Qty"
            }}
            name={idx}
            value={this.state.calculate_product_list[idx].qty}
            onChange={this.changeDraftqty.bind(this)}
          />
        </div>
        <div className="col-md-1 nopadding">
          {/* <button type="button" onClick={this.addCalculateProduct}>+</button> */}
          {/* <button>-</button> */}
          <button type="button" className="btn btn-secondary btn-remove-calculate" onClick={() => this.removeCalculateProduct(idx)}>
            -
          </button>
        </div>
      </FormGroup>
    );
  }

  openTicketOptions() {
    if (this.state.ticket_options && this.state.ticket_options.length != 1){
      this.setState({ openpopoverTicketOptions: !this.state.openpopoverTicketOptions, classFocus: "when-focus", ticketCollapse: !this.state.ticketCollapse})
    }
  }

  showPopoverTicketOptions() {
    const activitys = this.state.ticket_options
    .map(prod => {
      return (
          <div className="no-underline" key={prod.id} >
            <div className="row margin-px">
              <div className="col-9 no-pad activity-text" onClick={() => this.changeTicketOptionSelected(prod.id, this.state.ticket_options)}>
                <span>{prod.name}</span>
                <p>{prod.description}</p>
              </div>
              <div className="col-3 no-pad">
                <button className="activity-plus-icon" onClick={() => this.changeTicketOptionSelected(prod.id, this.state.ticket_options)} style={{width: "35px",
                          height: "35px"}}>
                  <FaPlus style={{fontSize:'12px'}}/>
                </button>
              </div>
            </div>
          </div>
      );
    });


    const activitys2 = this.state.ticket_options
    .map(prod => {
      return (
        <div className="row no-underline" style={{justifyContent:"space-around"}} key={prod.id} >
          <div className={`col-10 ${this.props.park_id != 9 ? 'bg-hover' : 'bg-hover2'}`} onClick={() => this.changeTicketOptionSelected(prod.id, this.state.ticket_options)}>
            <div className="activity-text">
              <span className="">{prod.name}</span>
              <p className="">{prod.description}</p>
            </div>
          </div>  
          <div className="no-pad m-auto " style={{maxWidth:"10.66%"}}>
              <button className="activity-plus-icon" onClick={() => this.changeTicketOptionSelected(prod.id, this.state.ticket_options)} style={{width: "35px",
                      height: "35px"}}>
                  <FaPlus style={{fontSize:'12px'}}/>
              </button>
          </div>
        </div>
      );
    });

    var poper = (
      <Popover
        trigger="legacy"
        isOpen={this.state.openpopoverTicketOptions}
        toggle={() => this.openTicketOptions()}
        target="ticketOptions"
        className="main-activity"
        style={{paddingRight:"1rem"}}
      >
        <PopoverBody>
          {activitys2}
        </PopoverBody>
      </Popover>
    );

    var modal = (
      <Modal
      trigger="legacy" 
      className="main-activity"
      target="ticketOptions"
      isOpen={this.state.openpopoverTicketOptions}
      toggle={() => this.openTicketOptions()}
      >
        <ModalBody
        className="style-modal">
        {activitys}
        </ModalBody>
      </Modal>
    ); 
    return (
      <React.Fragment>
        {" "}
        {this.state.width < 1000 ? modal : poper}{" "}
      </React.Fragment>
    );
  }
  
  showMonetaryValue() {
    let monetary_values = this.state.monetary_value
    let color, color2 = ''
    
    color = '#02543F'
    color2 = '#019645'

    const prices = this.state.monetary_value.map((item, index) => {
      return (
        <div
          key={item.id} 
          onClick={() => this.changeMonetaryValueSelected(item.id, monetary_values)}
          className={`${item.selected ? "selected" : ''}  `}
          style={{border: `solid ${color}`, borderRadius: '10px'}}
        >
          ${item.value}
        </div>
      )
    });    
    let resultsRender = [];
    let price_value_list = [];
    for (var i = 0; i < prices.length; i++) {   
        price_value_list.push(prices[i]);
        if (i % 3 === 2) {
            resultsRender.push(
                <div className="flex-container" key={i}>
                  {price_value_list}
                </div>
            );
            price_value_list = [];
        }
    }

    if ( prices.length % 3 != 0) {
      resultsRender.push(
        <div className="flex-container">
          {price_value_list}
        </div>
      );
    }
    return (
      <>
        <div className="monetary-value">
          {resultsRender}
        </div>
        <div className="col-md-12 col-xs-12 head-card">
          <div>  
            <u style={{color: color2, cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.setState({showVoucherDetail: true})}>Shop by Experience</u>
            <Form style={{marginTop: '16px'}} onSubmit={e => {
                this.AddVoucherSubmit(e, true);
              }}
            >
              <div className="btn-voucher .custom-button">
                <FormGroup className="field-step3">
                  <Input
                    valid={this.state.validate.fnState === "has-success"}
                    autoComplete="off"
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="form-control step3"
                    placeholder="Recipient's Name*"
                    required
                    value={this.state.first_name}
                    onBlur={e => {
                      this.validBlur(e);
                    }}
                    onChange={e => {
                      // this.validateText(e)
                      this.handleChange(e);
                    }}
                  />
                  <FormFeedback className="text-left ftnamevalid" valid>
                    Recipient's Name*
                  </FormFeedback>
                </FormGroup>
                <FormGroup className="field-step3">
                  <Input
                    autoComplete="off"
                    type="email"
                    name="email"
                    id="email"
                    className="form-control step3"
                    placeholder="Recipient's Email"
                    value={this.state.email || ""}
                    onChange={e => {
                      // force email to be lower case
                      e.target.value = e.target.value.toLowerCase();
                      this.validateEmail(e);
                      this.handleChange(e);
                    }}
                    valid={this.state.validate.emailState === "has-success"}
                    invalid={this.state.validate.emailState === "has-danger"}
                  />
                  <FormFeedback className="text-left emailvalid" valid>
                    Recipient's Email
                  </FormFeedback>
                  <FormFeedback className="text-left">Provide a valid email</FormFeedback>
                </FormGroup>

                <FormGroup className="field-step3">
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    className="form-control step3"
                    placeholder="Recipient's Phone Number"
                    valid={this.state.validate.phoneState === "has-success"}
                    invalid={this.state.validate.phoneState === "has-danger"}
                    value={this.state.phone}
                    onBlur={e => {
                      this.validBlurPhone(e);
                    }}
                    onChange={e => {
                      this.validBlurPhone(e);
                      this.handleChange(e);
                    }}
                  />
                  <FormFeedback className="text-left phonevalid" valid>
                    Recipient's Phone Number
                  </FormFeedback>
                  <FormFeedback className="text-left">Provide a valid mobile number</FormFeedback>
                </FormGroup>
                <div>
                  <Button
                    id="isncontinue"
                    name="isncontinue"
                    className="v_details"
                    onClick={() => (is_continue = false)}
                    type="submit"
                  >
                    ADD TO CART
                  </Button>
                  {this.props.list_recepient.length > 0 && (
                    <Button
                      id="continue"
                      name="continue"
                      className="v_details"
                      type="button"
                      onClick={() => this.props.actionCollapse("ContactCollapse")}
                    >
                      GO TO CHECKOUT
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </>
    )
  }

  changeMonetaryValueSelected(id, monetary_values) {
    let updated_values = monetary_values.map(prod => {
      if (prod.id == id) {
        prod.selected = true;
      }
      else {
        prod.selected = false;
      }
      return prod
    })
    this.setState({monetary_values: updated_values})
  }

  changeTicketOptionSelected(id, ticketOptions) {
    // eslint-disable-next-line
    ticketOptions.filter(obj => obj.id === parseInt(id)).map(prod => {
      this.setState({
        ticketOption: prod.name,
        ticketOptionID: prod.id
      })
    })
    this.setState({
      ticketCollapse: !this.state.ticketCollapse,
      showVoucherDetail: true,
    })
    this.openTicketOptions();
  }

  getCardDetail() {
    let color, color2 = ''
    color = '#02543F'
    color2 = '#019645'
    
    const cardCollapse = (
      <Card>
        <CardBody className="cardbody-step3">
          {this.props.enableTicketOptions ?
            <div>
              <div className="input-group-prepend mt-3">
                <div className="input-group">
                  <Input
                    type="text"
                    onClick={() => this.openTicketOptions()}
                    name="ticketOptions"
                    id="ticketOptions"
                    className="when-focus"
                    value={this.state.ticketOption || ""}
                    required
                    placeholder="Select Ticket"
                    readOnly
                    autoComplete="off"
                  />
                  <i className="icon-on-input fa-user-alt float-right silver-color-icon">
                    <FaPlus onClick={() => this.openTicketOptions()} />
                  </i>
                </div>
              </div>
              {this.props.enableTicketOptions ? this.showPopoverTicketOptions() : ""}
            </div> : ""
          }
          {this.state.monetary_value.length > 0 && !this.state.showVoucherDetail ? this.showMonetaryValue() : ""}
          <br/>
          <Form style={this.state.monetary_value.length > 0 && !this.state.showVoucherDetail ? {visibility:'hidden', height:'0px'} : {visibility:'visible'}}
            onSubmit={e => {
              this.AddVoucherSubmit(e);
            }}
          >
            <Label for="numberParticipant" className="g-color">
              Please enter the voucher details
            </Label>
            {this.state.calculate_product_list.map((currElement, index) => {
              return this.calculateProductListHtml(index);
            })}
            <FormGroup className="row">
              <div className="col-md-4" style={{ paddingRight: "0" }}>
                <Select2
                  style={{ width: "100%" }}
                  className="form-control"
                  data={this.select2_list_product()}
                  options={{
                    placeholder: "Product"
                  }}
                  onChange={this.changeDraftProduct.bind(this)}
                  value={this.state.draft_calculate.product_id}
                />
              </div>
              <div className="col-md-4" style={{ paddingRight: "0" }}>
                <Select2
                  className="form-control"
                  data={this.select2_list_segment(
                    this.state.draft_calculate.product
                  )}
                  value={this.state.draft_calculate.segment_id}
                  onChange={this.changeDraftSegment.bind(this)}
                  options={{
                    placeholder: "Segment"
                  }}
                />
              </div>
              <div className="col-md-3" style={{ paddingRight: "0" }}>
                <Select2
                  id="qty-selector"
                  className="form-control"
                  data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
                  options={{
                    placeholder: "Qty"
                  }}
                  value={this.state.draft_calculate.qty}
                  onChange={this.changeDraftqty.bind(this)}
                />
              </div>
              <div className="col-md-1 nopadding">
                <button type="button" className="btn btn-secondary" onClick={this.addCalculateProduct}>
                  +
                </button>
              </div>
            </FormGroup>
            <FormGroup className="field-step3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                <Input
                  id="amount"
                  name="amount"
                  onChange={this.handleChangeNumber.bind(this)}
                  value={this.state.amount || 0}
                  placeholder="Amount"
                  min={1}
                  max={1000000}
                  type="number"
                  step="1"
                  required
                  style={{ textAlign: "center" }}
                  autoFocus
                  data-for={"voucher_tooltip"}
                  data-html={true}
                  data-event={"change"}
                  data-tip={this.get_campaign_message(campaign_value)}
                  readOnly={!this.state.editable_voucher_amount}
                >
                </Input>
                <ReactTooltip
                  id="voucher_tooltip"
                  getContent={(dataTip) => `${dataTip}`}
                  overridePosition={(
                    { left, top },
                    currentEvent, currentTarget, node,
                    place, desiredPlace, effect, offset) => {
                    const d = document.documentElement;
                    if (this.state.width > SCREEN_WIDTH_LIMIT - 1) {
                      left = Math.min(d.clientWidth - node.clientWidth, left) + 50;
                      top = Math.min(d.clientHeight - node.clientHeight, top);
                      left = Math.max(0, left);
                      return { top, left }
                    } else {
                      left = Math.min(d.clientWidth - node.clientWidth, left);
                      top = Math.min(d.clientHeight - node.clientHeight, top) - 100;
                      left = Math.max(0, left);
                      top = Math.max(0, top) + 100;
                      return { top, left }
                    }
                  }}
                  place={this.state.width > SCREEN_WIDTH_LIMIT ? "right" : "left"}
                  type="light"
                  border={true}
                  multiline={true}
                  resizeHide={false}
                  scrollHide={false}
                  borderClass={"voucher_tooltip_border"}
                  // wrapper="span"
                  effect="solid"
                  style={{ textAlign: "center" }}
                />
                <InputGroupAddon addonType="append">.00</InputGroupAddon>
              </InputGroup>
            </FormGroup>
            
            {
              this.state.monetary_value.length > 0 && this.state.showVoucherDetail ? 
                <u style={{color: color2, cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.setState({showVoucherDetail: false})}>Shop by Value</u>
              : 
              ''
            }
            <FormGroup style={{marginTop: '16px'}} className="field-step3">
              <Input
                valid={this.state.validate.fnState === "has-success"}
                autoComplete="off"
                type="text"
                name="first_name"
                id="first_name"
                className="form-control step3"
                placeholder="Recipient's Name*"
                required
                value={this.state.first_name || ""}
                onBlur={e => {
                  this.validBlur(e);
                }}
                onChange={e => {
                  // this.validateText(e)
                  this.handleChange(e);
                }}
              />
              <FormFeedback className="ftnamevalid" valid>
                Recipient's Name*
              </FormFeedback>
            </FormGroup>
            <FormGroup className="field-step3">
              <Input
                autoComplete="off"
                type="email"
                name="email"
                id="email"
                className="form-control step3"
                placeholder="Recipient's Email"
                value={this.state.email || ""}
                onChange={e => {
                  // force email to be lower case
                  e.target.value = e.target.value.toLowerCase();
                  this.validateEmail(e);
                  this.handleChange(e);
                }}
                valid={this.state.validate.emailState === "has-success"}
                invalid={this.state.validate.emailState === "has-danger"}
              />
              <FormFeedback className="emailvalid" valid>
                Recipient's Email
              </FormFeedback>
              <FormFeedback>Provide a valid email</FormFeedback>
            </FormGroup>

            <FormGroup className="field-step3">
              <Input
                type="text"
                name="phone"
                id="phone"
                className="form-control step3"
                placeholder="Recipient's Phone Number"
                valid={this.state.validate.phoneState === "has-success"}
                invalid={this.state.validate.phoneState === "has-danger"}
                value={this.state.phone || ""}
                onBlur={e => {
                  this.validBlurPhone(e);
                }}
                onChange={e => {
                  this.validBlurPhone(e);
                  this.handleChange(e);
                }}
              />
              <FormFeedback className="phonevalid" valid>
                Recipient's Phone Number
              </FormFeedback>
              <FormFeedback>Provide a valid mobile number</FormFeedback>
            </FormGroup>
            {/* <div className="box-voucher col-md-12">
              <h3>${this.state.amount && this.state.amount.toFixed(2)}</h3>
              <div className="text-desc">
                <p className="title">{this.state.first_name}</p>
                <p>Trees Adventure Vouchers</p>
                <p>@{this.props.park_name}</p>
              </div>
            </div> */}
            <hr />
            <div className="btn-voucher .custom-button">
              <div className="col-md-12">
                <Button
                  id="isncontinue"
                  name="isncontinue"
                  className="v_details"
                  onClick={() => (is_continue = false)}
                  type="submit"
                >
                  ADD TO CART
                </Button>
                {this.props.list_recepient.length > 0 && (
                  <Button
                    id="continue"
                    name="continue"
                    className="v_details"
                    type="button"
                    onClick={() => this.props.actionCollapse("ContactCollapse")}
                  >
                    GO TO CHECKOUT
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
    return cardCollapse;
  }
}

export default VoucherDetail;
