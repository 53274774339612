import React, { Component } from "react";
import { Button, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import Stepper from "../Layout/Stepper";
import { FaExclamationCircle } from "react-icons/fa";
import { api_get_windcave_session, api_create_booking_step4_check, api_park_cache, api_customer_feedback } from "../../api/api";
import axios from "axios";
import { FaUserAlt, FaFacebook, FaHome, FaApple } from "react-icons/fa";
import Moment from "moment";
import { colorSetProperty, setGoogleFont, setHeadTag, unsetFooter, setTitle, convertToTime } from "../../helpers/helpers";
import axiosRetry from 'axios-retry';

class ConfirmPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.match.params.bookingcode,
      data: null,
      flag: null,
      parks: null,
      park_id: null,
      park_data: null,
      updatedFont: false,
      updatedHeadTag: false,
      openModal: false,
      updatedTitle: false,
      errorMsg: false,
      reco: false,
      isLoading: true,
      total_amount: false,
      voucher_list: [],
      pay_amount: false,
      amount_paid: false,
      feedback: "",
      isSendFeedback: false
    };
  }

  componentDidMount() {
    const booking_id = this.props.match.params.booking_id;
    const voucher_list = JSON.parse(sessionStorage.getItem('voucher_list'));
    let windcave_session = sessionStorage.getItem('windcave_session');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('sessionId') != null) {
      windcave_session = urlParams.get('sessionId')
    }

    let total_discount = 0;
    if (voucher_list != null ){
        voucher_list.map((discount, index) => {
        total_discount += discount.amount
      })
    }

    const client = axios.create({ baseURL: api_create_booking_step4_check, headers: {'Content-Type': 'application/json'} });
    axiosRetry(client, { retries: 30, retryDelay: (retryCount) => {
      return retryCount * 750;
    } });
    let parameters = {
      booking_id: booking_id,
      windcave_session: windcave_session,
      pay_amount: '',
    };
    client.get("/check", { params: parameters }).then( ({data}) => {
      if (Object.keys(data).length > 0) {
        this.setState({
          name: data.data[0]["name"],
          data: data,
          isLoading: false,
          total_amount: data.data[0]['amount'],
          amount_paid: data.data[0]['amount_paid'],
          voucher_list: voucher_list,
          pay_amount: data.data[0]['amount'] + total_discount
        });
        if (data['errorMsg']) {
          this.setState({
          errorMsg: data['errorMsg'],
          reco: data.reco
          })
        }
        this.setState({flag: 1});
      }
      else {
        this.setState({flag: 2});
      }
      let products = [];
      // eslint-disable-next-line
      data.participants.map(p => {
        products.push([{
          'product': p.segment_name,
          'qty': p.quantity
        }])
      })
      let argsDataLayer = {
        'event': 'step6', 'step': 'purchase',
        'total': data.data[0]["amount"].toFixed(2),
        'location': data.data[0]["location_id"][1],
        'activity': data.booking_detail[0]["name"],
        'products': products
      };
      this.pushDataLayer(argsDataLayer);
    })
  }

  componentDidUpdate() {
    let { park_data, updatedFont, updatedHeadTag } = this.state;
    if (park_data) {
      // Call set property
      this.customizeColor(park_data[0])
      if (this.state.updatedTitle == false) {
        setTitle(park_data[0].name);
        this.setState({ updatedTitle: true });
      }
      // - custom font -
      let snipFont = park_data[0].t_google_font_snippets + ' <style> body {' + park_data[0].t_font_to_use + '} </style>';
      if (snipFont && updatedFont == false) {
        setGoogleFont(snipFont);
        this.setState({ updatedFont: true })
      }
      // - head tag -
      let snipheadtag = park_data[0].t_embed_head_tag;
      if (snipheadtag && updatedHeadTag == false) {
        setHeadTag(snipheadtag);
        this.setState({updatedHeadTag: true})
      }
    }else {
      this.getParkData();
      // - unset footer -
      unsetFooter();
    }
  }

  getParkData() {
    const bookData = this.state.data.data;
    if (bookData.length > 0) {
      axios.get(api_park_cache).then(({ data }) => {
        let park_id = bookData[0].location_id[0];
        let park_data = data.result.filter(park => park.id == park_id)
        this.setState({
          park_id: park_id,
          parks: data.result,
          park_data: park_data
        })
      }).catch(({ error }) => {});
    }
  }

  customizeColor(park) {
    // Customize color
    const colors = {
        'color1': park.t_color1,
        'color2': park.t_color2,
        'color3': park.t_color3,
    }
    colorSetProperty(colors);
  }

  pushDataLayer = (args={}) => {
    if (process.env.REACT_APP_ENV == 'production_tz') {
      // Push data layer to Tag Manager
      window.dataLayer.push(args);
      // Logging the data layer
      console.log(`Push DataLayer: ${args.event}`, window.dataLayer);
    }
    let params = new URLSearchParams(this.props.location.search);
    let treesadventure = params.get("treetops");
    if (treesadventure) {
      window.tzdataLayer.push(args);
      console.log(`Push TARONGA DataLayer: ${args.event}`, window.tzdataLayer);
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return 0;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  backtowebsite() {
    window.location.href = this.state.park_data[0].website
  }

  openModalContact = () => {
    this.setState({
      openModal: !this.state.openModal
    });
  }

  modalContact = () => {
    let modal = ""
    if (this.state.park_data) {
      modal = (
        <Modal
          trigger="legacy"
          className="contact-details"
          target="contactButton"
          isOpen={this.state.openModal}
          toggle={this.openModalContact}
        >
          <ModalHeader className="modal-contact-title">Contact Details</ModalHeader>
          <ModalBody className="style-modal">
            <div className="row margin-px">
              <div className="col col-md-12">
                <h6 className="modal-contact-body">Phone Number</h6>
                <p className="modal-contact-data">{this.state.park_data[0].phone}</p>
              </div>
              <div className="col col-md-12">
                <h6 className="modal-contact-body">Email</h6>
                <p className="modal-contact-data">{this.state.park_data[0].email}</p>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )
    }
    return (
      <React.Fragment>
        {modal}
      </React.Fragment>
    )
  }

  postOrderApi = (url, param) => {
    let params = {
      jsonrpc: "2.0",
      method: "call",
      id: null,
      params: param
    };
    return axios.post(url, params, {
      headers: { "Content-Type": "application/json;charset=utf-8" }
    });
  };

  fetchNewSession = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let hasSafari = userAgent.match(/Safari/i);
    let hasOtherBrowser = /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox|GSA/i.test(userAgent)
    let isAppleVendor = /Apple/i.test(navigator.vendor);
    let iosDevice = /iPad|iPhone|iPod/.test(userAgent)
    let isMac = /Macintosh/.test(userAgent)
    let pay_method = ['card']

    // Apple Pay only works on ios device and safari
    if ( (iosDevice && hasSafari && isAppleVendor && !hasOtherBrowser) || (isMac && hasSafari && !hasOtherBrowser) ) {
      this.setState({isIOS: true})
      pay_method.push('applepay');
    }
    // Google pay works on most of the android, browser and mac
    if (!iosDevice || ( isMac && hasOtherBrowser )) {
      pay_method.push('googlepay');
    }
    
    this.postOrderApi(api_get_windcave_session, {
      'total_amount': this.state.pay_amount,
      'booking_id': this.state.data.data[0].id,
      'previous_windcave_session': this.state.previousWindcaveSession,
      'exp_from_record': true,
      'voucher_list': this.state.voucher_list,
      'full_page': true,
      'payment_method': pay_method,
    })
    .then(({ data }) => {
      if ("result" in data) {
        if ('errors' in data.result) {
          if (data.result.errors[0]['target'] == "expires"){
            alert('Your Session Has Expired, No transaction has been made')
            window.location.href = '/#/order/0'
          }
        }
        const windcaveRef = data["result"]["links"].filter(link => link['method'] === 'REDIRECT')[0]['href']
        window.location.href = windcaveRef
      }
    })
  }

  sendFeedback = () => {
    if(this.state.feedback !== ""){
      try {
        let params = {
          jsonrpc: "2.0",
          method: "call",
          id: null,
          params: {
            booking_id: this.props.match.params.booking_id,
            value: this.state.feedback
          }
        };
        let res = axios.post(api_customer_feedback, params, {headers: { "Content-Type": "application/json;charset=utf-8" }})
        res.then(({ data }) => {
          if (data['error']) {
            alert('error')
          } else {
            this.setState({
              isSendFeedback: true
            })
          }
        })
        .catch(err => {
          this.props.toggleLoading(false);
          console.log(err);
        });



      } catch(err) {
        console.log(err)
      }
    }
  };
  
  successHeader() {
    return (
      <>
        Thanks {this.state.data.customer.first_name} {this.state.data.customer.name}<br/>
        for booking with us! <br/>
        <div className="sub">Your booking has been confirmed</div>
        <div style={{marginTop: "16px", marginBottom: "16px", fontSize: "16px", fontWeight: "100"}}>
          {this.state.isSendFeedback ? "Thanks for your feedback" : (
            <>
              <Input
                type="select"
                name="park"
                ref="park"
                onChange={e =>
                  this.setState({
                    feedback: e.target.value
                  })
                }
                readOnly>
                  <option value="">How do you hear about us ?</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Ads">Ads</option>
                  <option value="A friend">A friend</option>
                  <option value="Family">Family</option>
                  <option value="Returning customer">Returning customer</option>
              </Input>
              <Button
                    onClick={this.sendFeedback}
                    className="btn btn-confirmation"
                    style={{width: "100%", marginTop:"12px"}}
                  >
                  Submit
              </Button>
            </>
            )
          }
        </div>
      </>
    )
  }

  renderFooter(contactButton) {
    return(
      <>
        {this.state.errorMsg ? 
        <>
          The transaction has been cancelled
          <br/>
          <br/>
          No payment has been processed.
          <br />
          <br />
          <Button className="btn btn-confirmation" onClick={() => this.fetchNewSession()}>
            Retry Payment
          </Button>
          <br />
          <br />
        </>
        : 
        <>
          A confirmation email and waiver form has been sent to <span>{this.state.data.customer.email}</span>.
          <br />
          <br />
          Please <span>{contactButton}</span> if you have any questions about your booking.
          <br />
          <br />
          <Button className="btn btn-confirmation" onClick={() => this.backtowebsite()}>
            View website
          </Button>
          <br />
          <br />
        </>
        }
      </>
    )
  }

  renderBookingDetail() {
    return (
      <div className="confirm-box">
        <div className="col col-xs-12 confirm-details">
          <div className="row">
              <div className="col-md-12 title">BOOKING DETAILS</div>
          </div>
          {/* Booking Reference */}
          <div className="row">
              <div className="col-md-6 label-detail">
                  <span className="text-left">
                    Booking Reference
                  </span>
              </div>
              <div className="col-md-6 price-qty text-right">
                  {this.state.name}
              </div>
          </div>
          <hr />
          {/* Total Paid */}
          <div className="row">
              <div className="col-md-6 label-detail">
                  <b>Total Paid</b>
              </div>
              <div className="col-md-6 price-qty text-right">
                  <b>${this.state.data.data[0].amount.toFixed(2)}</b>
              </div>
          </div>
        </div>
    </div>
    )
  }

  render() {
    let step = [true, true, true, true, true];
    let refer = this.getParameterByName('a');
    const modalContact = this.modalContact();
    if (!this.state.data && !this.state.flag) {
        return (
            <React.Fragment>
                <Stepper step={step} park_id={this.state.park_id} parks={this.state.parks} />
            </React.Fragment>
        );
    }else if(!this.state.data && this.state.flag === 2){
        return(
            <React.Fragment>
                <Stepper step={step} park_id={this.state.park_id} parks={this.state.parks} />
                <div className="container confirm" style={{ marginTop: 20 }}>
                  <center>
                    <i className="exclamation">
                      <FaExclamationCircle />
                    </i>
                    <br />
                    <b>Booking code {this.state.name} doesn't exist.</b>
                    <br />
                  </center>
                </div>
              </React.Fragment>
        );
    }else{

        let valDate = "", duration = "", participants = "", time = "", min = "", hour ="";
        if (this.state.data.booking_detail[0].start_session.date && this.state.data.booking_detail[0].start_session.time) {
          // Reformat time (float to HH:mm)
          time = convertToTime(this.state.data.booking_detail[0].start_session.time);
          // Prepare date with moment to format
          let mDate = Moment(this.state.data.booking_detail[0].start_session.date)
          if (mDate.isValid()) {
            valDate = mDate.format("dddd D MMMM") + " at " + time;
          }else {
            valDate = this.state.booking_detail[0].start_session.date + " at " + time;
          }
            console.log(this.state.data.booking_detail[0].start_session.date);
        }
        duration = this.state.data.booking_detail[0].duration / 60;
        const maxPar = this.state.data.participants.length;
        let i = 1;
        // eslint-disable-next-line
        this.state.data.participants.map(p => {
            participants += p.quantity +" "+p.segment_name;
            if (i < maxPar){
                i++;
                participants += ", ";
            }
        });
        let contactButton = null;
        let facebook_link = null;
        let website_link = null;
        if (this.state.park_data) {
          contactButton = <a href={this.state.park_data[0].contact_us_link}>contact the park</a>;
          facebook_link = this.state.park_data[0].facebook_link
          website_link = this.state.park_data[0].website
          
          if (!this.state.park_data[0].contact_us_link) {
            contactButton = (
              <span
                id="contactButton"
                name="contactButton"
                className="btn-confirm-contact"
                onClick={this.openModalContact}
                style={{lineHeight:"inherit",cursor:"pointer"}}
              >
                <text>contact the park</text>
              </span>
            )
          }
        }
        return (
          <React.Fragment>
            {modalContact}
            <Stepper step={step} park_id={this.state.park_id} parks={this.state.parks} />
            <div className={`container confirm ${this.state.isLoading ? "loading" : ''} `} style={{ marginTop: 20 }}>
              {refer.length > 0 ?
              <div className="confirm-thanks">
                {this.state.errorMsg ? 'Transaction Failed': this.successHeader()}
              </div>
              : "" }
              { this.state.errorMsg ? <p style={{color: 'red'}}>({this.state.reco}) {this.state.errorMsg}</p>  : ''}
              { this.state.errorMsg ? '' : this.renderBookingDetail() }
              
             <br />
             <div className="confirm-text">
              {this.renderFooter(contactButton)}
                <a href={facebook_link}><FaFacebook /></a>
                <a href={website_link}><FaHome /></a>
             </div>
            </div>
          </React.Fragment>
        );
    }
  }
}

export default ConfirmPage;
