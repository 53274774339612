import React, { Component } from "react";
import ProductCard from "./step1/ProductCard";
import MainActivity from "./step2/MainActivity";
import ParticipantCard from "./step2/ParticipantCard";
import ProfileCard from "./step3/ProfileCard";
import PaymentMethodCard from "./step5/PaymentMethodCard";
import WindcaveCard from "./step6/WindcaveCard";
import ConfirmRequest from "../OrderFinish/ConfirmRequest";
import { createBrowserHistory } from "history";
import { fetchProducts } from "../../actions/products";
import { fetchParks } from "../../actions/global";
import { numberSpinner, colorSetProperty, setGoogleFont, setHeadTag, setFooter, setTitle } from "../../helpers/helpers"
import {
  api_voucher,
  api_create_booking_step2,
  api_create_booking_step3,
  api_create_booking_step4,
  api_create_booking_step4_check,
  api_create_booking_request,
  api_get_windcave_session,
  api_government_voucher
} from "../../api/api";
import axios from "axios";
import Stepper from "../Layout/Stepper";
import { connect } from "react-redux";
import ReactGA from 'react-ga';
import axiosRetry from 'axios-retry';
import {
  FaAngleUp,
  FaAngleDown,
  FaAssistiveListeningSystems
} from "react-icons/fa";
import Moment from "moment";

import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Popover,
  PopoverBody,
  PopoverHeader
} from "reactstrap";

var open = false;
var timeSpinner = ""

let argsDataLayer = {};
class BaseOrder extends Component {
  state = {
    min: 10,
    sec: 0,
    park_id: null,
    park_name: "",
    enable_ticket_options: false,
    product_id: null,
    ParticipantCardCollapse: true,
    MainActivityCollapse: false,
    ParticipantOpen: false,
    ProductCardCollapse: false,
    ProfileCardCollapse: false,
    PaymentMethodCardCollapse: false,
    productSelected: null,
    ticketOptionSelected: null,
    tempPrice: null,
    productById: null,
    customer: null,
    participant: null,
    mainactivity: null,
    total_payment: 0,
    htmlParticipant: [],
    voucher: { id: null, amount: 0, valid: null, message: "", code: "", Type: "" },
    voucher_list: [],
    isLoading: false,
    booking_id: null,
    summary_participant: "",
    preference: "",
    totalParticipant: 0,
    dataRequest: null,
    payError: false,
    payErrorMsg: "",
    datetime_booking: { date: "", time: "" },
    resetTotalParticipant: 0,
    secondActivity: false,
    check_promocodes: false,
    productParticipant: null,
    mode: null,
    secondSelectDateTime: false,
    two_exp: 0,
    remove_voucher: false,
    add_voucher: false,
    totalAmount: 0,
    // submit button
    participantEnableSubmit: false,
    profileEnableSubmit: false,
    paymentEnableSubmit: false,
    parks: null,
    updatedFont: false,
    updatedHeadTag: false,
    updatedFooter: false,
    updatedTitle: false,
    discount_code: {},
    windcaveLink: undefined,
    previousWindcaveLink: undefined,
    disable_voucher_btn: false,
    windcaveSession: false,
    previousWindcaveSession: false,
    error: {
      errorCode: false,
      errorMsg: false,
      errorDetail: false,
      counter: 0,
    },
    isIOS: false,
    orderSummaryCollapse: true,
    confirmedBookingModal: false,
    showAppliedVoucherWarningModal: false,
    stateName: false,
    removeAppliedVoucher: false,
    showExpiredPopUp: false,
    triggerdExpiredPopUp: false,
    secondActivityHtml: [],
    terms_and_conditions_link: "",
    privacy_statement_link : "",
    contact_us_link: "",
    progress: 20,
    government_voucher_code: "",
    gov_voucher_amount: 0,
    clicked_from_ta: false
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    this.toggleLoading(true);
    let state = { ...this.state };
    var first = true;
    if (newProps.parks) {
      // eslint-disable-next-line
      newProps.parks.map(park => {
        if (park.id === parseInt(this.state.park_id)) {
          // eslint-disable-next-line
          state.park_name = park.name;
          state.parks = park;
          state.enable_ticket_options = park.enable_activity_option
          state.terms_and_conditions_link = park.terms_and_conditions_link
          state.privacy_statement_link   = park.privacy_statement_link
          state.contact_us_link = park.contact_us_link
        }
      });
    }
    if (newProps.products) {
      state.ProductCardCollapse = true;
      state.ParticipantCardCollapse = false;
      // Update & push args data layer step 1
      argsDataLayer = { 'event': 'step1', 'step': 'location', 'location': state.park_name };
      this.pushDataLayer(argsDataLayer);
      newProps.products
        .filter(obj => obj.company_id[0] === parseInt(this.state.park_id))
        // eslint-disable-next-line
        .map(product => {
          state.ProductCardCollapse = false;
          state.ParticipantCardCollapse = true;
          if (first) {
            state.ProductCardCollapse = false;
            state.ParticipantCardCollapse = true;
            state.MainActivityCollapse = false;
            first = false;
            state.product_id = product.id;
            state.productSelected = product;
            open = true;
            // Update & push args data layer step 2
            argsDataLayer = { 'event': 'step2', 'step': 'activity', 'activity': product.name };
            this.pushDataLayer(argsDataLayer)
            state.progress = 40;
          } else {
            state.ProductCardCollapse = false;
            state.ParticipantCardCollapse = false;
            state.MainActivityCollapse = true;
            state.productSelected = null;
            state.progress = 20;
          }
        });
    }
    this.setState(state);
    this.toggleLoading(false);
  }

  componentDidUpdate(prevProps, prevState) {
    // Call Customize
    const currentUrl = document.location.hash.split("/");
    let checkSelectPark = currentUrl.includes("order") && currentUrl.includes("0") ? true : false;

    if (checkSelectPark) {
      if (this.state.updatedTitle == false) {
        setTitle("Taronga Zoo");
        this.setState({ updatedTitle: true });
      }
    }

    if (this.state.parks) {
      this.customizeColor(this.state.parks);
      if (this.state.updatedTitle == false) {
        setTitle(this.state.parks.name);
        this.setState({ updatedTitle: true });
      }
      // - custom font -
      let snipFont = this.state.parks.t_google_font_snippets + ' <style> body {' + this.state.parks.t_font_to_use + '} </style>';
      if (snipFont && this.state.updatedFont == false) {
        setGoogleFont(snipFont);
        this.setState({ updatedFont: true })
      }
      // - head tag -
      let snipheadtag = this.state.parks.t_embed_head_tag;
      if (snipheadtag && this.state.updatedHeadTag == false) {
        setHeadTag(snipheadtag);
        this.setState({ updatedHeadTag: true })
      }
      // - footer tag -
      let footerTag = this.state.parks.t_footer;
      if (footerTag && this.state.updatedFooter == false) {
        setFooter(footerTag);
        this.setState({ updatedFooter: true })
      }
    }

    const history = createBrowserHistory();
    history.listen((location, action) => {
      let location_hash = location.hash.split("/");
      var urlChange = location_hash[2];
      if (this.state.park_id !== urlChange) {
        this.setState({ park_id: urlChange });
      }
    });
    if (this.state.remove_voucher && this.state.secondActivity) {
      this.secondActivityPromocode(false)
      this.setState({
        remove_voucher: false
      })
    }
    if (this.state.voucher_list.length > 0) {
      if (prevState.ParticipantCardCollapse || prevState.MainActivityCollapse) {
        this.setState({ voucher_list: [] })
      }
      let promo_result = this.state.voucher_list.filter(obj => obj.type === 'promocode')
      if (promo_result.length > 0) {
        if (this.state.add_voucher && this.state.secondActivity) {
          this.secondActivityPromocode(true)
          this.setState({
            add_voucher: false
          })
        }
        if (!this.state.check_promocodes) {
          // let discount_html = document.getElementById('discount-participant')
          // if(discount_html){
          //   discount_html.classList.add("d-none");
          // }
          // this.setState({'check_promocodes': true})
        }
      } else {
        if (this.state.check_promocodes) {
          // let discount_html = document.getElementById('discount-participant')
          // if(discount_html){
          //   discount_html.classList.remove("d-none");
          // }
          this.setState({ 'check_promocodes': false })
        }
      }
    } else {
      if (this.state.check_promocodes) {
        // let discount_html = document.getElementById('discount-participant')
        // if(discount_html){
        //   discount_html.classList.remove("d-none");
        // }
        this.setState({ 'check_promocodes': false })
      }
    }
  }

  componentWillMount() {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if (reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }

  componentDidMount() {
    const { fetchProducts, fetchParks } = this.props;
    fetchProducts();
    fetchParks();
    this.setState({
      park_id: this.props.match.params.park_id
    });

    // Set interval
    this.myInterval = setInterval(() => {
      const { sec, min } = this.state
      if (sec > 0) {
        this.setState(({ sec }) => ({
          sec: sec - 1
        }))
      }
      if (sec === 0) {
        if (min === 0) {
          clearInterval(this.myInterval)
          window.location.reload();
        } else {
          this.setState(({ min }) => ({
            min: min - 1,
            sec: 59
          }))
        }
      }
    }, 1000)

    if (process.env.REACT_APP_ENV == 'production_tz') {
      // initialize google analytick
      ReactGA.initialize('UA-145448805-1');
      ReactGA.pageview(window.location.href);
    }
  }

  customizeColor(park) {
    // Customize color
    const colors = {
      'color1': park.t_color1,
      'color2': park.t_color2,
      'color3': park.t_color3,
      'color4': park.t_color4,
    }
    colorSetProperty(colors);
  }

  toggleConfirmedBookingModal = () => {
    this.setState({ confirmedBookingModal: !this.state.confirmedBookingModal })
  }
  toggleExpiredPopup = () => {
    this.setState({ showExpiredPopUp: !this.state.showExpiredPopUp })
  }
  toggleAppliedVoucher = () => {
    this.setState({ showAppliedVoucherWarningModal: !this.state.showAppliedVoucherWarningModal })
  }

  getAppliedVoucherWarningModal() {
    return (
      <div>
          <Modal 
              backdrop={"static"}
              keyboard={false}
              centered={true}
              autoFocus={true}
              zIndex={2000}
              isOpen={this.state.showAppliedVoucherWarningModal}
            >
              <ModalHeader className="modal-warning">
                <div>
                    <b>Attention Required</b>
                </div>
              </ModalHeader>
              <ModalBody className="modal-warning">
                <div>
                  Changes to your selection will remove the voucher or promo code. Please make sure to re-enter it to apply the discount.
                </div>
              </ModalBody>
              <ModalFooter className="modal-warning">
                <Button 
                  className="btn btn-secondary" 
                  onClick={() => {
                    this.removeVoucherWarning();
                  }}>
                    OK
                  </Button>
              </ModalFooter>
          </Modal>
      </div >
    );
  }

  getConfirmedBookingNotice() {
    return (
      <div>
          <Modal 
              backdrop={"static"}
              keyboard={false}
              centered={true}
              autoFocus={true}
              zIndex={2000}
              isOpen={this.state.confirmedBookingModal}
            >
              <ModalHeader className="modal-warning">
                  <div>
                      <b>Booking Updated</b>
                  </div>
              </ModalHeader>
              <ModalBody className="modal-warning">
                  <div>
                    Our team is working on confirming your booking.
                    <br/>
                    Please do not edit this reservation.
                    <br/>
                    Thank you!
                  </div>
              </ModalBody>
              <ModalFooter className="modal-warning">
                <Button 
                  className="btn btn-secondary" 
                  onClick={() => {window.location.reload()}}>OK</Button>
              </ModalFooter>
          </Modal>
      </div >
    );
  }

  collapseState(state, StateName) {
    state.ParticipantCardCollapse =
      StateName === "ParticipantCardCollapse" ? true : false;
    state.MainActivityCollapse =
      StateName === "MainActivityCollapse" ? true : false;
    state.ProductCardCollapse =
      StateName === "ProductCardCollapse" ? true : false;
    state.ProfileCardCollapse =
      StateName === "ProfileCardCollapse" ? true : false;
    state.PaymentMethodCardCollapse =
      StateName === "PaymentMethodCardCollapse" ? true : false;
    // state.WindcaveCardCollapse =
    //   StateName === "WindcaveCardCollapse" ? true : false;
    
    if(StateName === "ProductCardCollapse"){
      state.progress = 0;
    }else if(StateName === "MainActivityCollapse"){
      state.progress = 20;
    }else if(StateName === "ParticipantCardCollapse"){
      state.progress = 40;
    }else if(StateName === "ProfileCardCollapse"){
      state.progress = 60;
    }else if(StateName === "PaymentMethodCardCollapse"){
      state.progress = 80;
    }


    if (state.PaymentMethodCardCollapse == true) {
      state.WindcaveCardCollapse = true;
    }
    else {
      state.WindcaveCardCollapse = false;
    }
    return state;
  }

  secondActivityPromocode(promocode) {
    let state = { ...this.state };
    let count_qty = 0;
    var secondActivityHtml = []
    state.total_payment = 0
    state.htmlParticipant = []
    
    state.secondActivityHtml.push(
      <div className="row">
        <div className="col-md-8 col-8 participant title"> 
          {Moment(this.state.datetime_booking.date).format("dddd D MMMM") + " at " + this.state.datetime_booking.time}
        </div> 
      </div>
    )
    
    // eslint-disable-next-line
    this.state.productParticipant.map(prod => {
      if (prod.qty > 0) {
        if (this.state.secondActivity && this.state.secondSelectDateTime) {
          this.state.secondActivity.absale_product_price
            .filter(app => app.segment_name === prod.segment_name)
            // eslint-disable-next-line
            .map(a => {
              state.total_payment = state.total_payment + prod.price * prod.qty;
              state.totalParticipant += prod.qty;
              state.htmlParticipant.push(
                <div key={`${a.id}-participant-unique-second`} className="row">
                  <div className="col-md-3 col-3 participant">
                    {/* {count_qty === 0 && this.state.productSelected.experience_id.name}{" "} */}
                    {count_qty === 0 && this.state.productSelected.name}{" "}
                  </div>{" "}
                  <div className="col-md-3 col-3 price-qty text-right">
                    <span className="participant text-left">
                      {" "}
                      {prod.segment_name}{" "}
                    </span>
                    <span> {prod.qty} &nbsp;</span>
                  </div>{" "}
                  <div className="col-md-2 col-2 price-qty text-left">
                    <span className="price"> x ${prod.price}</span>
                  </div>
                  <div className="col-md-4 col-4 price-total">
                    $ {(prod.qty * prod.price).toFixed(2)}{" "}
                  </div>{" "}
                </div>
              );
              if (state.totalParticipant >= 10 && this.state.productSelected.available_discount && !this.state.secondActivity) {
                state.htmlParticipant.push(
                  <div key="discount-participant" id="discount-participant" className="row">
                    <div className="col-md-8 col-8 participant">
                      {this.state.productSelected.discount_product_experience}% Discount for 10+ attendees
                    </div>{" "}
                    <div className="col-md-4 col-4 price-total text-right">
                      $ - {((prod.qty * prod.price) * this.state.productSelected.discount_product_experience / 100).toFixed(2)}
                    </div>{" "}
                  </div>
                )
              }
              secondActivityHtml.push(
                <div className="row">
                  <div className="col-md-3 col-3 participant">
                    {count_qty === 0 && this.state.secondActivity.experience_id.name}{" "}
                  </div>{" "}
                  <div className="col-md-3 col-3 price-qty text-right">
                    <span className="participant text-left">
                      {" "}
                      {prod.segment_name}{" "}
                    </span>
                    <span> {prod.qty} &nbsp;</span>
                  </div>{" "}
                  <div className="col-md-2 col-2 price-qty text-left">
                    <span className="price">
                        {" "}
                        x ${a.price}
                    </span>
                  </div>
                  <div className="col-md-4 col-4 price-total">
                    ${" "}
                    {(prod.qty * a.price).toFixed(
                      2
                    )}{" "}
                  </div>{" "}
                </div>
              );
              if (promocode) {
                state.total_payment = state.total_payment + (a.price * prod.qty);
              } else {
                state.total_payment = state.total_payment + (a.price * prod.qty);
                state.total_payment = (state.total_payment - (state.total_payment * state.two_exp) / 100)
              }
              this.setState({ total_payment: state.total_payment })

            });
        }
      }
    });
    let htmlParticipant = (
      <React.Fragment>
        {" "}
        {state.htmlParticipant} {secondActivityHtml}{" "}
      </React.Fragment>
    )
    this.setState({ htmlParticipant: htmlParticipant })
  }

  removeVoucherWarning() {
    this.ActionCollapseState(this.state.stateName);
    this.toggleAppliedVoucher()
  }

  ActionCollapseState = StateName => {
    let state = { ...this.state };
    if (state.voucher_list.length > 0 && !state.showAppliedVoucherWarningModal) {
      this.setState({stateName: StateName})
      this.toggleAppliedVoucher()
    }
    else {
      state = this.collapseState(state, StateName);
      this.setState(state);
    }
  };

  saveRequest = customer => {
    this.toggleLoading(true);
    let state = { ...this.state };
    let value = {};
    const history = createBrowserHistory();
    value["participant_segment_ids"] = [];
    // eslint-disable-next-line
    this.state.tempPrice.map(prod => {
      if (prod.qty > 0) {
        value["participant_segment_ids"].push([
          0,
          0,
          { segment_id: prod.segment_id[0], quantity: prod.qty }
        ]);
      }
    });
    this.setState({ profileEnableSubmit: false });
    this.postOrderApi(api_create_booking_request, {
      value: {
        preference: this.state.preference,
        park_id: this.state.productSelected.company_id[0],
        desired_date: this.state.datetime_booking.date,
        product_ids: [[6, 0, [this.state.productSelected.id]]],
        participant_ids: value["participant_segment_ids"]
      },
      customer: customer
    })
      .then(({ data }) => {
        if ("result" in data) {
          this.toggleLoading(true);
          state.dataRequest = data;
          this.setState(state);
          history.push({
            pathname: "/#/booking-request/",
            state: { some: "state" }
          });
          console.log("saverequest")
        } else {
          this.toggleLoading(true);
          const error_message = data["error"]["data"]["message"];
          alert(error_message);
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ profileEnableSubmit: true });
        this.toggleLoading(true);
        alert("there is a problem when saving data");
      });
  };
  savePark = (productSelected, park_name, park_id) => {
    let state = { ...this.state };
    state.productSelected = productSelected;
    state.park_name = park_name;
    state.customer = null;
    state.participant = null;
    state.datetime_booking["date"] = "";
    state.datetime_booking["time"] = "";
    state.product_id = productSelected.id;
    state.park_id = park_id;
    this.collapseState(state, "ParticipantCardCollapse");
    this.setState(state);

  };

  saveProduct = (productSelected, ticketSelected) => {
    let state = { ...this.state };
    state.productSelected = productSelected;
    state.ticketOptionSelected = ticketSelected;
    state.customer = null;
    state.participant = null;
    state.datetime_booking["date"] = "";
    state.datetime_booking["time"] = "";
    state.product_id = productSelected.id;
    state.totalParticipant = 0;
    this.collapseState(state, "ParticipantCardCollapse");
    // Update & push args data layer step 2
    argsDataLayer = { 'event': 'step2', 'step': 'activity', 'activity': productSelected.name };
    this.pushDataLayer(argsDataLayer)
    this.setState(state);
  };

  saveParticipant = (
    selectDateTime,
    productParticipant,
    preference,
    mode,
    secondSelectDateTime = false,
    secondActivity = false,
    two_exp = 0,
    _is_save_to_backend = true,
    ignore_discount_package = false
  ) => {
    let state = { ...this.state };
    let value = {};
    state["secondActivity"] = secondActivity;
    state["datetime_booking"]["date"] = selectDateTime["date"];
    state["datetime_booking"]["time"] = selectDateTime["time"];

    value["session_id"] = selectDateTime.id;
    value["participant_segment_ids"] = [];
    value["participant_segment_ids_gtm"] = [];
    if (_is_save_to_backend) {
      this.toggleLoading(true);
      state = this.collapseState(state, "ProfileCardCollapse");
    }
    state.two_exp = two_exp
    state.mode = mode
    state.productParticipant = productParticipant
    state.total_payment = 0;
    state.totalParticipant = 0;
    state.htmlParticipant = [];
    state.datetime_booking = selectDateTime;
    state.summary_participant = "";
    state.preference = preference;
    state.secondSelectDateTime = secondSelectDateTime
    let count_qty = 0;
    var secondActivityHtml = []
    let showSecondActivityDate = true
    // eslint-disable-next-line
    productParticipant.map((prod, index) => {
      if (index == 0) {
        state.htmlParticipant.push(
          <div key={`${prod.id}-participant-unique-0`} className="row">
            <div className="col-md-8 col-8 participant title"> 
              {Moment(this.state.datetime_booking.date).format("dddd D MMMM") + " at " + this.state.datetime_booking.time}
            </div> 
          </div>
        )
      }
      if (prod.qty > 0) {
        value["participant_segment_ids"].push([
          0,
          0,
          { segment_id: prod.segment_id[0], quantity: prod.qty }
        ]);
        value["participant_segment_ids_gtm"].push([
          { 'product': prod.segment_name, 'qty': prod.qty, 'price': prod.price.toFixed(2) }
        ])
        // state.summary_participant = state.summary_participant + prod.segment_id[1] + " " + prod.qty + " ";

        state.summary_participant =
          state.summary_participant.split(" ").join("_") +
          " " +
          prod.segment_id[1] +
          "_" +
          prod.qty +
          " ";
        state.summary_participant = state.summary_participant
          .trim()
          .split(" ")
          .join(", ")
          .split("_")
          .join(" ");
        state.total_payment = state.total_payment + prod.price * prod.qty;
        state.totalParticipant += prod.qty;
        state.htmlParticipant.push(
          <div key={`${prod.id}-participant-unique-1`} className="row">
            <div className="col-md-3 col-3 participant">
              {/* {count_qty === 0 && this.state.productSelected.experience_id.name}{" "} */}
              {count_qty === 0 && this.state.productSelected.name}{" "}
            </div>{" "}
            <div className="col-md-3 col-3 price-qty text-right">
              <span className="participant text-left">
                {" "}
                {prod.segment_name}{" "}
              </span>
              <span> {prod.qty} &nbsp;</span>
            </div>{" "}
            <div className="col-md-2 col-2 price-qty text-left">

              <span className="price"> x ${prod.price}</span>
            </div>
            <div className="col-md-4 col-4 price-total">
              $ {(prod.qty * prod.price).toFixed(2)}{" "}
            </div>{" "}
          </div>
        );
        if (secondActivity && secondSelectDateTime) {
          secondActivity.absale_product_price
            .filter(app => app.segment_name === prod.segment_name)
            // eslint-disable-next-line
            .map(a => {
              if (showSecondActivityDate) {
                secondActivityHtml.push(
                  <div key={`${prod.id}-participant-unique-2`} className="row">
                    <div className="col-md-8 col-8 participant title"> 
                      {Moment(secondSelectDateTime.date).format("dddd D MMMM") + " at " + secondSelectDateTime.time}
                    </div> 
                  </div>

                )
                showSecondActivityDate = false
              }
              secondActivityHtml.push(
                <div key={`${prod.id}-participant-unique-2`} className="row">
                  <div className="col-md-3 col-3 participant">
                    {count_qty === 0 && secondActivity.experience_id.name}{" "}
                  </div>{" "}
                  <div className="col-md-3 col-3 price-qty text-right">
                    <span className="participant text-left">
                      {" "}
                      {prod.segment_name}{" "}
                    </span>
                    <span> {prod.qty} &nbsp;</span>
                  </div>{" "}
                  <div className="col-md-2 col-2 price-qty text-left">
                    <span className="price">
                      {" "}
                      x ${a.price}
                    </span>
                  </div>
                  <div className="col-md-4 col-4 price-total">
                    ${" "}
                    {(prod.qty * a.price).toFixed(
                      2
                    )}{" "}
                  </div>{" "}
                </div>
              );
              state.total_payment = state.total_payment + (prod.qty * a.price)
            });
        }
        count_qty = count_qty + 1;
      }
    });
    // Sum the total payment
    let { totalAmount, totalPayment } = this.amountTotalPayment(state.total_payment, secondActivity, two_exp)
    state.totalAmount = totalAmount;
    state.total_payment = totalPayment;

    state.htmlParticipant = (
      <React.Fragment>
        {" "}
        {state.htmlParticipant} {secondActivityHtml}{" "}
      </React.Fragment>
    );
    if (state.totalParticipant === 0) {
      this.setState(state)
    }
    if (mode === 1) {
      if (
        value["participant_segment_ids"].length <= 0 ||
        !value["session_id"]
      ) {
        this.toggleLoading(false);
        return false;
      }


      state.participant = value;
      let booking_detail_ids = [];
      booking_detail_ids.push([
        0,
        0,
        {
          product_id: state.productSelected.id,
          start_session_id: state.participant.session_id
        }
      ]);

      if (secondSelectDateTime && secondActivity) {
        booking_detail_ids.push([
          0,
          0,
          {
            product_id: secondActivity.id,
            start_session_id: secondSelectDateTime.id
          }
        ]);
      }
      if (_is_save_to_backend) {
        this.setState({ participantEnableSubmit: false });
        this.postOrderApi(api_create_booking_step2, {
          booking_id: this.state.booking_id,
          booking: {
            location_id: state.productSelected.company_id[0],
            booking_detail_ids: booking_detail_ids,
            participant_segment_ids: state.participant.participant_segment_ids
          },
          ignore_discount_package: ignore_discount_package
        })
          .then(({ data }) => {
            this.toggleLoading(false);
            if (data["error"]) {
              alert(data["error"]["data"]["message"].replace(/None$/g, ''))
              this.setState({ participantEnableSubmit: false });
            } else {
              state.booking_id = data["result"]["booking_id"];
              state.discount_code = data["result"]["discount_code"];
              this.setState(state);
              // Update & push args data layer step 3
              argsDataLayer = {
                'event': 'step3', 'step': 'participants',
                'total': state.total_payment.toFixed(2),
                'location': state.park_name,
                'activity': state.productSelected.name,
                'products': state.participant.participant_segment_ids_gtm
              };
              this.pushDataLayer(argsDataLayer);
            }
          })
          .catch(err => {
            this.toggleLoading(false);
            console.log(err);
            alert("there is a problem when saving data");
          });
      } else {
        this.toggleLoading(false);
        this.setState(state);
      }
    } else {
      this.toggleLoading(false);
      state.tempPrice = productParticipant;
      state.participant = true;
      state.booking_id = 0;
      this.setState(state);
    }
  };

  saveCustomer = value => {
    this.toggleLoading(true);
    let state = { ...this.state };
    state.customer = value;
    if (
      state.customer["first_name"] &&
      state.customer["name"] &&
      state.customer["email"] &&
      state.customer["phone"]
    ) {
      this.setState({ profileEnableSubmit: false });
      this.postOrderApi(api_create_booking_step3, {
        customer: state.customer,
        booking_id: this.state.booking_id
      })
        .then(({ data }) => {
          if ("error" in data) {
            alert(data['error']['data']['message'], "there is a problem when saving data");
          } else {
            state = this.collapseState(state, "PaymentMethodCardCollapse");
            this.setState(state);
            // Update & push args data layer step 4
            argsDataLayer = { 'event': 'step4', 'step': 'contact' };
            this.pushDataLayer(argsDataLayer);
          }
          this.toggleLoading(false);
          if (data.result.status != 'Successfully update customer.') { 
            this.toggleConfirmedBookingModal()
          }
          else if (this.finalTotalPayment() > 0) {
            this.setState(state, ()=>{this.savePaymentWindcave()});
          }
          // console.log("save customer")
        })
        .catch(err => {
          this.setState({ profileEnableSubmit: true });
          this.toggleLoading(false);
          alert(err, "there is a problem when saving data");
        });
    }
  };

  amountTotalPayment(totalAmount, secondActivity, discMulti) {
    let state = { ...this.state };
    let totalPayment = totalAmount;
    if (state.totalParticipant >= 10 && state.productSelected.available_discount && !secondActivity) {
      totalPayment = totalPayment - (totalPayment * (state.productSelected.discount_product_experience / 100))
    }

    if (secondActivity) {
      totalPayment = (totalPayment - (totalPayment * discMulti) / 100);
    }
    return { totalAmount, totalPayment }
  }

  finalTotalPayment() {
    let val_voucher = 0;
    this.state.voucher_list.map(v => val_voucher = val_voucher + v.amount);
    let discount = 0;
    if (this.state.discount_code !== undefined && this.state.discount_code.amount !== undefined) {
      discount = discount + this.state.discount_code.amount
    }
    let total_payment = this.state.totalAmount - (val_voucher * -1) - discount

    return Math.max(0, total_payment)
  }

  savePaymentWindcave = (windcaveDeclined) => {
    if (this.state.error.counter == 2) {
      window.location.reload()
    }
    let state = { ...this.state };
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let hasSafari = userAgent.match(/Safari/i);
    let hasOtherBrowser = /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox|GSA/i.test(userAgent)
    let isAppleVendor = /Apple/i.test(navigator.vendor);
    let iosDevice = /iPad|iPhone|iPod/.test(userAgent)
    let isMac = /Macintosh/.test(userAgent)
    let pay_method = ['card']
    this.setState(state);
    this.setState({
      previousWindcaveSession: this.state.windcaveSession,
      disable_voucher_btn: true,
    });

    // Apple Pay only works on ios device and safari
    if ( (iosDevice && hasSafari && isAppleVendor && !hasOtherBrowser) || (isMac && hasSafari && !hasOtherBrowser) ) {
      this.setState({isIOS: true})
      pay_method.push('applepay');
    }
    // Google pay works on most of the android, browser and mac
    if (!iosDevice || ( isMac && hasOtherBrowser )) {
      pay_method.push('googlepay');
    }
    

    if (windcaveDeclined) {
      state = this.collapseState(state, "PaymentMethodCardCollapse");
    }
    
    this.toggleLoading(true);
    if (this.finalTotalPayment() > 0) {
      this.postOrderApi(api_get_windcave_session, {
        'total_amount': this.finalTotalPayment().toFixed(2),
        'booking_id': this.state.booking_id,
        'previous_windcave_session': this.state.previousWindcaveSession,
        'windcave_session': this.state.windcaveSession,
        'exp_from_record': true,
        'voucher_list': this.state.voucher_list,
        'payment_method': pay_method,
      })
        .then(({ data }) => {
          if ("result" in data) {

            if (data.result === false) {
              window.location.reload()
            }

            const windcaveRef = data["result"]["links"].filter(link => link['method'] === 'REDIRECT')[0]['href']
            const currentWindcaveLink = this.state.windcaveLink;
            const session = data['result']['id']
            if ("error" in data['result']) {
              this.setState({
                error: {
                  errorCode: data['result']['error']['errorCode'],
                  errorMsg: data['result']['error']['errorMsg'],
                  errorDetail: data['result']['error']['errorDetail'],
                  counter: this.state.error.counter + 1,
                }
              });
              console.log(this.state.error.counter)
            }

            this.setState({
              windcaveLink: windcaveRef,
              previousWindcaveLink: currentWindcaveLink,
              windcaveSession: session
            });
          }
        })
    }
    else {
      this.setState({
        windcaveLink: undefined,
        previousWindcaveLink: undefined,
        windcaveSession: false,
        previousWindcaveSession: false
      });
      this.toggleLoading(false);
    }
    this.setState({
      disable_voucher_btn: false,
    })
  }

  fetchNewSession = () => {
    if (this.finalTotalPayment() > 0) {
      this.postOrderApi(api_get_windcave_session, {
        'total_amount': this.finalTotalPayment().toFixed(2),
        'booking_id': this.state.booking_id,
        'previous_windcave_session': this.state.previousWindcaveSession,
        'windcave_session': this.state.windcaveSession,
        'exp_from_record': true,
        'voucher_list': this.state.voucher_list,
        'full_page': true,
        'payment_method': ['applepay', 'card'],
      })
        .then(({ data }) => {
          console.log(data)
          if ("result" in data) {

            if (data.result === false) {
              window.location.reload()
            }

            sessionStorage.setItem('voucher_list', JSON.stringify(this.state.voucher_list));
            const windcaveRef = data["result"]["links"].filter(link => link['method'] === 'REDIRECT')[0]['href']
            const currentWindcaveLink = this.state.windcaveLink;
            const session = data['result']['id']
            window.location.href = windcaveRef
          }
        })
    }
  }

  savePayment = () => {
    this.state.error.errorCode = false;
    this.state.error.errorMsg = false;
    this.state.error.errorDetail = false;
    this.setState({ paymentEnableSubmit: false });
    this.setState({ disable_voucher_btn: true });
    this.toggleLoading(true);
    
    if (this.finalTotalPayment() > 0) {
      const client = axios.create({ baseURL: api_create_booking_step4_check, headers: { 'Content-Type': 'application/json' } });
      axiosRetry(client, {
        retries: 30, retryDelay: (retryCount) => {
          return retryCount * 750;
        }
      });
      let parameters = {
        booking_id: this.state.booking_id,
        windcave_session: this.state.windcaveSession,
        pay_amount: this.finalTotalPayment(),
        voucher_list: JSON.stringify(this.state.voucher_list),
      };
      client.get("/check", { params: parameters }).then(({ data }) => {
        if (data.payments.length >= 1) {
          sessionStorage.setItem('voucher_list', JSON.stringify(this.state.voucher_list));
          sessionStorage.setItem('windcave_session', this.state.windcaveSession)
          window.location.href = "/#/booking/" + this.state.booking_id + "?a=1";
          argsDataLayer = {
            'event': 'step5', 'step': 'payment',
            'total': this.finalTotalPayment().toFixed(2)
          };
          this.pushDataLayer(argsDataLayer)
        } else {
          const error_message = data["error"]["data"]["message"];
          this.setState({ payError: true });
          this.setState({ payErrorMsg: error_message });
          this.setState({ paymentEnableSubmit: true });
          this.setState({ disable_voucher_btn: false });
          this.toggleLoading(false);
        }

      })

    } else {
      let voucher_list = this.state.voucher_list
      let refactored_voucher_list = []
      if (voucher_list.length > 0) {
        voucher_list.forEach((vc) => {
          if (vc['type'] == 'voucher') {
            refactored_voucher_list.push('VC_' + vc['id'] + ';' + -Math.abs(vc['amount']))
          }
          else if (vc['type'] == 'government_voucher') {
            refactored_voucher_list.push('GOV_' + vc['code'] + ';' + vc['amount'])
          }
          else if (vc['type'] == 'promocode') {
            refactored_voucher_list.push('PC_' + vc['id'] + ';' + vc['amount'])
          }
        })
      }
      debugger
      this.postOrderApi(api_create_booking_step4, {
        voucher_list: refactored_voucher_list,
        booking_id: this.state.booking_id,
        windcave_session: this.state.windcaveSession,
      })
        .then(({ data }) => {
          if ("result" in data) {
            window.location.href = "/#/booking/" + this.state.booking_id + "?a=1";
            argsDataLayer = {
              'event': 'step5', 'step': 'payment',
              'total': this.finalTotalPayment().toFixed(2)
            };
            this.pushDataLayer(argsDataLayer)
          } else {
            const error_message = data["error"]["data"]["message"];
            this.setState({ payError: true });
            this.setState({ payErrorMsg: error_message });
            this.setState({ paymentEnableSubmit: true });
            this.setState({ disable_voucher_btn: false });
            this.toggleLoading(false);
          }
        })
        .catch(err => {
          this.toggleLoading(false);
          console.log(err);
          alert("there is a problem when saving data");
        });
    }
  };

  postOrderApi = (url, param) => {
    let params = {
      jsonrpc: "2.0",
      method: "call",
      id: null,
      params: param
    };
    return axios.post(url, params, {
      headers: { "Content-Type": "application/json;charset=utf-8" }
    });
  };

  pushDataLayer = (args={}) => {
    if (process.env.REACT_APP_ENV == 'production_tz') {
      // Push data layer to Tag Manager
      window.dataLayer.push(args);
      // Logging the data layer
      console.log(`Push DataLayer: ${args.event}`, window.dataLayer);
    }
    let params = new URLSearchParams(this.props.location.search);
    let treesadventure = params.get("treetops");
    if (treesadventure) {
      window.tzdataLayer.push(args);
      console.log(`Push TARONGA DataLayer: ${args.event}`, window.tzdataLayer);
    }
  }
  

  checkVoucher = (code, isVoucher, isPromoCode, isGovVoucher) => {
    if (this.state.voucher_list.length >= 4) {
      this.setState({
        voucher: {
          id: null,
          amount: null,
          valid: false,
          message: "Maximum of four vouchers per booking."
        }
      });
      return false
    }
    this.toggleLoading(true);
    this.setState({
      error: {
        errorCode: false,
        errorMsg: false,
        errorDetail: false
      },
      add_voucher: true,
      check_promocodes: false,
      disable_voucher_btn: true,
    });
    let params = {
      jsonrpc: "2.0",
      method: "call",
      id: null,
      params: {
        booking_id: this.state.booking_id,
        park_id: this.state.productSelected.company_id[0],
        voucher_code: code,
        voucher_list: this.state.voucher_list,
        remove_promo: false,
        is_voucher: isVoucher,
        is_promo_code: isPromoCode,
        is_gov_voucher: isGovVoucher,
      }
    }; 
    return axios
      .post(api_voucher, params, {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      })
      .then(({ data }) => {
        let voucher_list = this.state.voucher_list
        let result = data["result"];
        if ("success" in result) {
          if (this.finalTotalPayment() <= 0) {
            this.setState({
              voucher: {
                id: null,
                amount: null,
                valid: false,
                message: "Maximum discount amount has been reached."
              }
            });
          }
          else {
            let success = result["success"];
            let state = { ...this.state };
            let vouch = {
              id: success["voucher_id"],
              amount: success["voucher_value"],
              valid: true,
              type: success['type'],
              message: success["messages"]["content"],
              code: code
            };
            const promo_exist = this.state.voucher_list.filter(
              ve => {
                if (ve.id === vouch["id"] && ve.type !== 'promocode' ) {
                  return ve
                }
                else {
                  return false
                }
              }
            );

            const vouch_exist = this.state.voucher_list.filter(
              ve => {
                if (ve.id === vouch["id"] && ve.type !== 'government_voucher' ) {
                  return ve
                }
                else {
                  return false
                }
              }
            );

            const gov_vouch_exist = this.state.voucher_list.filter(
              ve => {
                if (ve.type === 'government_voucher') {
                  if (ve.code === vouch["code"]) {
                    return ve
                  }
                  else {
                    return false
                  }
                }
                else {
                  return false
                }
              }
            );
            if (vouch_exist.length === 0 && isVoucher) {
              state.voucher_list.push(vouch);
              state.voucher = vouch;
            }
            else if (gov_vouch_exist.length === 0 && isGovVoucher) {
              state.voucher_list.push(vouch);
              state.voucher = vouch;
            }
            else if (promo_exist.length === 0 && isPromoCode) {
              state.voucher_list.push(vouch);
              state.voucher = vouch;
            }
            state.discount_code = result["discount_code"];
            if (isGovVoucher) {
              state.government_voucher_code = success["code"];
              state.gov_voucher_amount = success["voucher_value"];
            }
            this.setState(state, () => {this.savePaymentWindcave()});
            
          }
        } 
        else if ("error" in result) {
          this.setState({
            voucher: {
              id: null,
              amount: null,
              valid: false,
              message: result["error"]
            }
          });
        } 
        else {
          this.setState({
            voucher: {
              id: null,
              amount: null,
              valid: null,
              message: ""
            }
          });
        }
        this.setState({
          disable_voucher_btn: false,
        })
        this.toggleLoading(false);
      })
      .catch(err => {
        this.toggleLoading(false);
        console.log(err);
      });
  };

  removeVoucher = (id, code) => {
    var state = { ...this.state };
    let voucher_list 
    if (id) {
      voucher_list = state.voucher_list.filter(v => v.id !== id);
    }
    else if (!id && code) {
      voucher_list = state.voucher_list.filter(v => v.code !== code);
    }
    state["voucher_list"] = voucher_list;
    state.remove_voucher = true;
    state.voucher.message = "";
    state.error.errorCode = false;
    state.error.errorMsg = false;
    state.error.errorDetail = false;
    this.setState({
      disable_voucher_btn: true
    });

    let params = {
      jsonrpc: "2.0",
      method: "call",
      id: null,
      params: {
        booking_id: this.state.booking_id,
        park_id: this.state.productSelected.company_id[0],
        voucher_code: code,
        voucher_list: voucher_list,
        remove_promo: true
      }
    };
    axios.post(api_voucher, params, {
      headers: { "Content-Type": "application/json;charset=utf-8" }
    })
      .then(({ data }) => {
        let result = data["result"];
        state["discount_code"] = result["discount_code"];
        state.disable_voucher_btn = false
        this.setState(state, () => {this.savePaymentWindcave()});
      })
  };

  spinnerTimeout() {
    timeSpinner = setTimeout(
      function () {
        this.setState({ isLoading: false })
      }
        .bind(this),
      numberSpinner()
    );
  }
  stopSpinnerTimout() {
    clearTimeout(timeSpinner)
  }

  toggleLoading(value) {
    this.setState({ isLoading: value });
    if (value) {
      this.spinnerTimeout()
    } else {
      this.stopSpinnerTimout()
    }
  }

  handleProgressClick(stateName){
    if(stateName === 'ParticipantCardCollapse'){
      if(this.state.participant){
        this.ActionCollapseState(stateName);
      }
    }else if(stateName === 'ProfileCardCollapse'){
      if(this.state.customer){
        this.ActionCollapseState(stateName);
      }
    }else{
      this.ActionCollapseState(stateName);
    }
  }

  render() {
    const { min, sec } = this.state
    let style = {};
    style['margin'] = "0.5rem 15rem 1rem 0rem";
    if (this.state.parks != null) {
      if (this.state.parks == 9) {
        style['background-color'] = this.state.parks.t_color2
      }
    }

    if (this.state.total_payment <= 0) {
      style["visibility"] = "hidden";
      style["height"] = "0";
    }
    let voucher = "";
    let val_voucher = 0;
    if (this.state.voucher_list.length > 0) {
      let velement = [];
      // eslint-disable-next-line
      this.state.voucher_list.map(v => {  
        velement.push(
          <div key={`${v.id}-voucher`}className="row">
            <div className="col-md-6 col-6 v_summary-label">
              {" "}
              {v.code}{" "}
            </div>{" "}
            <div className="col-md-6 col-6 v_summary-price">
              $ {v.amount.toFixed(2)}{" "}
            </div>{" "}
          </div>
        );

        val_voucher = val_voucher + v.amount;
      });
      voucher = (
        <div>
          <hr /> {velement}{" "}
        </div>
      );
    }

    let step = [
      true,
      this.state.participant
        ? true
        : false || this.state.ParticipantCardCollapse,
      this.state.customer ? true : false || this.state.ProfileCardCollapse,
      this.state.PaymentMethodCardCollapse
    ];

    if(min < 1 && sec < 30 && this.state.triggerdExpiredPopUp === false){
      this.setState({triggerdExpiredPopUp: true});
      this.setState({showExpiredPopUp: true});
      if(sec < 1){
        window.location.reload();
      }
    }
    
    if (this.state.dataRequest == null) { 
      return (
        <div className={this.state.isLoading === true ? "loading" : ""}>
          <Stepper step={step} park_id={this.state.park_id} parks={this.props.parks} />{" "}
          {this.getConfirmedBookingNotice()}
          {this.getAppliedVoucherWarningModal()}
          <div className="container" style={{ marginTop: 20, marginBlock: 20 }}>
            <div id="progress">
              <div className="progress-line">
                <div className={this.state.progress > 0 ? "progress-item finished" : this.state.progress == 0 ? "progress-item ongoing" : "progress-item"}>
                  <div className="progress-title">Location</div>
                  <div className="circle" onClick={()=>this.ActionCollapseState('ProductCardCollapse')}>1</div>
                  <div className="circle-line" style={{borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px"}}></div>
                </div>                  
                <div className={this.state.progress > 20 ? "progress-item finished" : this.state.progress == 20 ? "progress-item ongoing" : "progress-item"}>
                  <div className="progress-title">Experience</div>
                  <div className="circle" onClick={()=>this.ActionCollapseState('MainActivityCollapse')}>2</div>
                  <div className="circle-line"></div>
                </div>
                <div className={this.state.progress > 40 ? "progress-item finished" : this.state.progress == 40 ? "progress-item ongoing" : "progress-item"}>
                  <div className="progress-title">Guests</div>
                  <div className="circle" onClick={()=>this.handleProgressClick('ParticipantCardCollapse')}>3</div>
                  <div className="circle-line"></div>
                </div>
                <div className={this.state.progress > 60 ? "progress-item finished" : this.state.progress == 60 ? "progress-item ongoing" : "progress-item"}>
                  <div className="progress-title">Contact Details</div>
                  <div className="circle" onClick={()=>this.handleProgressClick('ProfileCardCollapse')}>4</div>
                  <div className="circle-line"></div>
                </div>
                <div className={this.state.progress > 80 ? "progress-item finished" : this.state.progress == 80 ? "progress-item ongoing" : "progress-item"}>
                  <div className="progress-title">Checkout</div>
                  <div className="circle">5</div>
                  <div className="circle-line" style={{borderTopRightRadius: "8px", borderBottomRightRadius: "8px"}}></div>
                </div>
              </div>
            </div>
            <div className="row mbl-reverse">
              <div className="col-md-6 col-sm-12">
                <Modal 
                    backdrop={true}
                    keyboard={false}
                    centered={true}
                    autoFocus={true}
                    zIndex={2000}
                    isOpen={this.state.showExpiredPopUp}
                    className="modal-expired"
                  >
                    <ModalBody>
                        <div>
                          <h5 className="text-center b-color" style={{lineHeight: "25px"}}>Do you wish to continue?</h5>
                          <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"12px"}}>{min < 10 ? '0'+min : min} : {sec < 10 ? '0'+sec : sec}</div>
                          <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"12px"}}>
                            <Button 
                              className="btn btn-secondary" 
                              onClick={() => {
                                this.setState({ showExpiredPopUp: false});
                                this.setState({ triggerdExpiredPopUp: false});
                                this.setState({ min: 10});
                                this.setState({ sec: 0});
                              }}>
                                Yes  
                            </Button>
                            <Button 
                              className="btn btn-secondary" 
                              onClick={() => {window.location.reload()}}>
                                No
                            </Button>
                          </div>
                        </div>
                    </ModalBody>
                </Modal>
                
                <hr />
                <ProductCard
                  park_id={this.state.park_id}
                  park_name={this.state.park_name}
                  product_id={this.state.product_id}
                  productSelected={this.state.productSelected}
                  cardClass="card-product"
                  parks_all={this.props.parks}
                  products_all={this.props.products}
                  ProductCardCollapse={this.state.ProductCardCollapse}
                  data={true}
                  actionCollapse={this.ActionCollapseState}
                  action={this.savePark}
                />{" "}
                <hr />
                <MainActivity
                  park_id={this.state.park_id}
                  park_name={this.state.park_name}
                  product_id={this.state.product_id}
                  productSelected={this.state.productSelected}
                  cardClass="card-main"
                  enableTicketOptions={this.state.enable_ticket_options}
                  parks_all={this.props.parks}
                  products_all={this.props.products}
                  MainActivityCollapse={this.state.MainActivityCollapse}
                  data={true}
                  actionCollapse={this.ActionCollapseState}
                  action={this.saveProduct}
                  toggleLoading={this.toggleLoading.bind(this)}
                />{" "}
                <hr />
                <ParticipantCard
                  park_id={this.props.match.params.park_id}
                  park_name={this.state.park_name}
                  products_all={this.props.products}
                  parks_all={this.props.parks}
                  booking_id={this.state.booking_id}
                  productSelected={this.state.productSelected}
                  enableTicketOptions={this.state.enable_ticket_options}
                  productParticipant={this.state.productSelected}
                  ParticipantOpen={open}
                  cardClass="card-participant"
                  ParticipantCardCollapse={this.state.ParticipantCardCollapse}
                  data={this.state.participant ? true : false}
                  action={this.saveParticipant}
                  actionCollapse={this.ActionCollapseState}
                  toggleLoading={this.toggleLoading.bind(this)}
                  resetTotalParticipant={0}
                  enableSubmit={this.state.participantEnableSubmit}
                />{" "}
                <hr />
                <ProfileCard
                  cardClass="card-profile"
                  ProfileCardCollapse={this.state.ProfileCardCollapse}
                  data={this.state.customer ? true : false}
                  parks_all={this.props.parks}
                  totalParticipant={this.state.totalParticipant}
                  action={
                    this.state.totalParticipant <= 15
                      ? this.saveCustomer
                      : this.saveRequest
                  }
                  actionCollapse={this.ActionCollapseState}
                  min={min}
                  enableSubmit={this.state.profileEnableSubmit}
                />{" "}
                <hr />{" "}
                {this.state.totalParticipant <= 15 ? (
                  <>
                    <PaymentMethodCard
                      numberCircle={this.numberCircle}
                      park_id={this.state.park_id}
                      parks={this.props.parks}
                      cardStyle={this.cardStyle}
                      parks_all={this.props.parks}
                      payError={this.state.payError}
                      payErrorMsg={this.state.payErrorMsg}
                      cardClass="card-payment"
                      PaymentMethodCardCollapse={
                        this.state.PaymentMethodCardCollapse
                      }
                      gov_voucher_amount={this.state.gov_voucher_amount}
                      data={false}
                      actionCollapse={this.ActionCollapseState}
                      action={this.savePaymentWindcave}
                      price={this.state.total_payment}
                      voucher={this.state.voucher}
                      checkVoucher={this.checkVoucher}
                      government_voucher_code={this.state.government_voucher_code}
                      productSelected={this.state.productSelected}
                      toggleLoading={this.toggleLoading.bind(this)}
                      summary_participant={this.state.summary_participant}
                      datetime_booking={this.state.datetime_booking}
                      voucher_list={this.state.voucher_list}
                      remaining_payment={this.finalTotalPayment()}
                      removeVoucher={this.removeVoucher}
                      secondActivity={this.state.secondActivity}
                      enableSubmit={this.state.paymentEnableSubmit}
                      voucher_button={this.state.disable_voucher_btn}
                    />
                    <WindcaveCard
                      cardClass="card-windcave"
                      type="order"
                      productSelected={this.state.productSelected}
                      park_id={this.state.park_id}
                      WindcaveCardCollapse={this.state.WindcaveCardCollapse}
                      isIOS={this.state.isIOS}
                      PaymentCollapse={false}
                      parks_all={this.props.parks}
                      data={this.state.booking_id}
                      totalParticipant={this.state.totalParticipant}
                      actionCollapse={this.ActionCollapseState}
                      parks={this.props.parks}
                      action={this.savePayment}
                      totalAmount={this.finalTotalPayment()}
                      actionWindcave={this.savePaymentWindcave}
                      getNewSession={this.fetchNewSession}
                      enableSubmit={this.state.profileEnableSubmit}
                      windcaveLink={this.state.windcaveLink}
                      previousWindcaveLink={this.state.previousWindcaveLink}
                      toggleLoading={this.toggleLoading.bind(this)}
                      error={this.state.error}
                    />
                  </>
                ) : (
                  ""
                )}{" "}

                <div id="footer-text" />
              </div>
              {" "}
              { window.innerWidth >= 775 ?
                <div
                  className="col-md-6 col col-xs-12 order-sum-style"
                  style={style}
                >
                  <div className="row">
                    <div className="col-md-12 title">ORDER SUMMARY </div>{" "}
                  </div>

                  <div className="row">
                    <div className="col-md-12 title">
                      {this.state.park_name} 
                      <br />
                      {this.state.ticketOptionSelected}
                    </div>
                  </div>
                  {this.state.htmlParticipant}
                  {this.state.discount_code !== undefined && this.state.discount_code.amount !== undefined ?
                    <div key="discount-participant" id="discount-participant" className="row">
                      <div className="col-md-8 participant">
                        {this.state.discount_code.note}
                      </div>{" "}
                      <div className="col-md-4 price-total text-right">
                        $ - {Math.max(
                          0,
                          this.state.discount_code.amount
                        ).toFixed(2)}
                      </div>{" "}
                    </div>
                    : this.state.totalParticipant > 15 && this.state.booking_id != null ? 
                    <div key="discount-participant" id="discount-participant" className="row">
                      <div className="col-md-8 col-8 participant">
                        {this.state.productSelected.discount_product_experience}% Discount for 10+ attendees
                      </div>{" "}
                      <div className="col-md-4 col-4 price-total text-right">
                        $ - {(this.state.total_payment * 10/100).toFixed(2)}
                      </div>{" "}
                    </div> : <></>
                  }
                  {voucher} <hr />
                  <div className="row">
                    <div className="col-md-6 stotal">Total </div>{" "}
                    <div className="col-md-6 textnumber">
                      ${" "}
                      {this.finalTotalPayment().toFixed(2)}{" "}
                    </div>{" "}
                  </div>{" "}
                </div> : ''
              }
            </div>{" "}
            
            <div id="footer-policy">
              <a target="_blank" rel="noopener noreferrer" href={this.state.terms_and_conditions_link ? this.state.terms_and_conditions_link : ""}>Terms and Conditions</a> 
              <a target="_blank" rel="noopener noreferrer" href={this.state.privacy_statement_link ? this.state.privacy_statement_link : ""}>Privacy Statement</a> 
              <a target="_blank" rel="noopener noreferrer" href={this.state.contact_us_link ? this.state.contact_us_link : ""}>Contact Us</a> 
            </div>
          </div>{" "}
          {
            window.innerWidth < 775 ? 
            <div className="footer-phantom">
              <div
                className="order-sum-style-mobile sticky-footer"
                style={style}
              >
                <div className={`pr-3 pl-3 ${this.state.orderSummaryCollapse ? '' : 'pb-2 pt-2'}`}>
                  <div className={`${this.state.orderSummaryCollapse ? 'ticket-collapse' : 'ticket-show fade-in'}`}>
                    <div className="row">
                      <div className="col-md-12 title">ORDER SUMMARY </div>{" "}
                    </div>

                    <div className="row" style={{backgroundColor:"white"}}>
                      <div className="col-md-12 title">
                        {this.state.park_name} 
                        <br />
                        {this.state.ticketOptionSelected}
                      </div>
                    </div>
                    {this.state.htmlParticipant}
                    {this.state.discount_code !== undefined && this.state.discount_code.amount !== undefined ?
                      <div key="discount-participant" id="discount-participant" className="row">
                        <div className="col-md-8 col-8 participant">
                          {this.state.discount_code.note}
                        </div>{" "}
                        <div className="col-md-4 col-4 price-total text-right">
                          $ - {Math.max(
                            0,
                            this.state.discount_code.amount
                          ).toFixed(2)}
                        </div>{" "}
                      </div>
                      : this.state.totalParticipant > 15 && this.state.booking_id != null ? 
                      <div key="discount-participant" id="discount-participant" className="row">
                        <div className="col-md-8 col-8 participant">
                          {this.state.productSelected.discount_product_experience}% Discount for 10+ attendees
                        </div>{" "}
                        <div className="col-md-4 col-4 price-total text-right">
                          $ - {(this.state.total_payment * 10/100).toFixed(2)}
                        </div>{" "}
                      </div> : <></>
                    }
                    {voucher} 
                  </div>
                </div>
                <div className="phantom-dropdown">
                  <div className={`pr-3 pl-3`}>
                    <div className="row pr-3 pb-3 pt-3">
                      <div className="col-md-2 col-2">
                        <div 
                          className="order-sum-arrow" 
                          style={
                            this.state.parks != null ? 
                              this.state.parks.id == 9 ? 
                                {backgroundColor: this.state.parks.t_color3, borderColor: this.state.parks.t_color3} 
                                : 
                                {} 
                              : 
                              {}}>
                          {
                            this.state.orderSummaryCollapse ?
                            <FaAngleUp 
                              style={{fontSize:"24px"}}
                              onClick={
                                () => this.setState({ orderSummaryCollapse: !this.state.orderSummaryCollapse})}
                            />
                            :
                            <FaAngleDown 
                              style={{fontSize:"24px"}}
                              onClick={
                                () => this.setState({ orderSummaryCollapse: !this.state.orderSummaryCollapse})}
                            />
                          }
                        </div>
                      </div>{" "}
                      <div className="col-md-4 col-4 stotal mb-0" style={{color:"white", fontSize:'20px', fontWeight:'bold'}}>Total</div>{" "}
                      <div className="col-md-6 col-6 pr-0 textnumber"  style={{color:"white", fontSize:'20px', fontWeight:'bold'}}>
                        ${" "}
                        {this.finalTotalPayment().toFixed(2)}{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>
                </div>
              </div>{" "}
            </div> : ''
          }
        </div>
      );
    } else {
      return <ConfirmRequest data={this.state.dataRequest}/>;
    }
  }
}
const mapStateToProps = state => {
  return {
    parks: state.parkReducer.data,
    products: state.productReducer.data,
    productFetching: state.productReducer.data
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchProducts: () => dispatch(fetchProducts(true)),
    fetchParks: () => dispatch(fetchParks())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseOrder);
